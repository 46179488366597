import React from "react";
import { AboutImage } from "../../Components/About/AboutImage";
import leftquote from '../../Assets/leftquote.png'
import rightquote from '../../Assets/rightquote.png'

export default function About() {
    return (
        <div className="h-full relative">
            <div className="absolute top-0 left-0 w-[100%] overflow-hidden fill-[#FFFFFF]">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none"
                    style={{ transform: 'rotateY(180deg' }}>
                    <path d="M1200 0L0 0 892.25 114.72 1200 0z" className="shape-fill"></path>
                </svg>
            </div>

            <div className="zeroScreen:flex zeroScreen:flex-col mostPhone:grid mostPhone:grid-cols-2 justify-center items-center gap-1 w-full h-full zeroScreen:px-2
            mostPhone:px-4 smallDesktop:px-16 zeroScreen:py-[3rem] mostPhone:py-[5rem] sm:py-[7rem] smallDesktop:py-52 bg-greyTheme">
                <div className="col-span-1 pt-5 mostPhone:ml-[4rem] sm:ml-[6rem] zeroScreen:w-[60%] mostPhone:w-3/4">
                    <AboutImage />
                </div>
                <div className="flex flex-col justify-center w-full min-h-full h-auto" >
                    <div className="flex flex-col items-center">
                        <img src={leftquote} alt="quote" loading="lazy" className="self-start zeroScreen:h-[0.5rem] mostPhone:h-[1rem] sm:h-[1.3rem]"/>
                        <div>
                            <p className="font-bold tracking-tight pl-8 zeroScreen:text-sm mostPhone:text-base sm:text-2xl smallDesktop:text-3xl 
                            lg:text-4xl xl:text-5xl pr-8 text-center">
                                We Provide End to End <span className="tracking-tight text-orangeTheme" > Fire And Life Safety</span>  Solution Design And Installation of Fire Protection System
                            </p>
                        </div>
                        <img src={rightquote} alt="quote" loading="lazy" className="self-end zeroScreen:h-[0.5rem] mostPhone:h-[1rem] sm:h-[1.3rem]"/>
                    </div>
                </div>
            </div>
            <div className="absolute bottom-0 left-0 w-full overflow-hidden line-height-0">
                <svg className="relative block w-full" viewBox="0 0 1200 120" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z" className="shape-fill" fill="#FFFFFF"></path>
                </svg>
            </div>

        </div>

    );
}
