import React from "react";
import { Form } from "./Form";
import Contact_img from '../../Assets/shape.png';
import getintouchbg from '../../Assets/getintouchbg.png'


export default function Contact() {
    return (
        <div id="contact"  className="mt-[3rem] smallPhone:mt-[5rem] smallPhone:pt-[2rem] xl:pt-[7rem] relative">
            <img src={getintouchbg} alt='background' loading="lazy" className="absolute w-[100%] -z-[1] h-[103%] xl:h-[95%]" />
            <div className="flex flex-col md:flex-row items-center justify-center gap-y-[3rem] md:justify-evenly pt-10 pb-[3rem] xl:pb-48 h-full w-full">
                <Form />
                <div className="flex justify-center items-center w-[40%]">
                    <img src={Contact_img} alt="contact_img" className="h-[100%] object-contain" />
                </div>
            </div>
        </div>
    );
}
