import { useState } from 'react';
import servicesbg from '../../Assets/servicesbg.png'
import ServicesData from '../../Data/ServicesData'
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

export default function Services(){
    const [readMore,setReadMore] = useState(Array.from({length:14},() => false));

    // setting read more whether to hide content or not
    const handleReadMore = (index) => {
        const newReadMoreData = [...readMore] ;
        newReadMoreData[index] = !newReadMoreData[index];
        setReadMore(newReadMoreData);
    }

    return(
        <div id='services'>
            <img src={servicesbg} alt="background" loading="lazy" className='zeroScreen:h-[2rem] smallPhone:h-[3rem] mostPhone:h-[4rem] md:h-[6rem] w-[100vw]'/>
            <div>
                <div className={`flex flex-col justify-center items-center mt-[1.5rem] zeroScreen:gap-y-[3rem] sm:gap-y-[5rem] overflow-scroll`} 
                style={{ scrollbarWidth: 'thin', scrollbarColor: 'transparent transparent' }}>
                    <p className='font-bold zeroScreen:text-lg smallPhone:text-xl mostPhone:text-2xl sm:text-4xl smallDesktop:text-5xl'>Services we provide</p>
                    <div className='grid 2xl:grid-cols-2 max-w-[80%] zeroScreen:gap-y-[2rem] sm:gap-y-[5rem] gap-x-[2rem] max-h-[45rem]'>
                        {
                            ServicesData?.map((data) => (
                                <div key={data?.id} className='flex zeroScreen:flex-col md:flex-row zeroScreen:gap-y-6
                                 mostPhone:gap-x-6 justify-center items-center'>
                                    <img src={data?.link} alt='services' loading='lazy'
                                    className='zeroScreen:w-[12.063rem] zeroScreen:h-[9.625rem] mostPhone:w-[15.063rem] mostPhone:h-[12.625rem] 
                                    sm:w-[18.063rem] sm:h-[15.625rem] smallDesktop:w-[21.063rem] smallDesktop:h-[18.625rem]'/>
                                    <div className='flex flex-col gap-y-2 zeroScreen:items-center md:items-start'>
                                        <h2 className='font-extrabold zeroScreen:text-sm mostPhone:text-base sm:text-lg smallDesktop:text-xl'>{data?.heading}</h2>
                                        <p className={`${readMore[data?.id] ? 'max-h-[12rem]' : 'overflow-y-hidden mostPhone:max-h-[4rem] sm:max-h-[6rem]'}
                                        zeroScreen:text-xs sm:text-sm smallDesktop:text-base zeroScreen:text-center md:text-start`}>{data?.description}</p>
                                        <div className='flex items-center cursor-pointer' onClick={() => handleReadMore(data?.id)}>
                                            {
                                                readMore[data?.id] ? (<p className='font-bold zeroScreen:text-xs sm:text-sm smallDesktop:text-base'>Read Less</p>) 
                                                : ( <p className='font-bold zeroScreen:text-xs sm:text-sm smallDesktop:text-base'>Read More</p>)
                                            }
                                            <MdOutlineKeyboardArrowRight className='text-3xl' />
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}