import React from 'react'
import GalleryScroll from '../../Components/Gallery/GalleryScroll'
import leftquote from '../../Assets/leftquote.png'
import rightquote from '../../Assets/rightquote.png'

export default function GallerySection() {
  return (
    <div className="flex zeroScreen:flex-col smallDesktop:flex-row mx-3 largeDevice:mt-[8rem] zeroScreen:p-4 mostPhone:px-20 mostPhone:py-10 
    sm:p-20 items-center gap-x-[4rem] gap-y-[2rem]">
      <div className="zeroScreen:w-[100%] smallDesktop:w-[50%] flex justify-center items-center">
        <div className='flex flex-col zeroScreen:gap-y-[0.5rem] smallPhone:gap-y-[1rem]'>
          <img src={leftquote} alt='quote' loading='lazy' className='w-[7%]'/>
          <h2 className="text-center zeroScreen:text-lg smallPhone:text-2xl sm:text-4xl smallDesktop:text-3xl largeDevice:text-5xl font-bold 
          self-center smallPhone:px-[3rem]">
            Our <span className="text-[#FF5B36]">Duties</span> And{" "}
            <span className="text-[#FF5B36]">Functions</span>
          </h2>
          <img src={rightquote} alt='quote' loading='lazy' className='w-[7%] self-end'/>
        </div>
      </div>
      <GalleryScroll />
    </div>
  )
}
