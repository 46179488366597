import CompanyLogosData from "../../Data/CompanyLogosData"

export default function OurCustomers() {
    return (
        <div id="industryserve" className="flex w-screen justify-center mt-[4rem] py-[3rem] border-t-2">
            <div className="flex flex-col w-[90%] smallDesktop:w-[60%] items-center gap-y-[3rem]">
                <div className="flex flex-col items-center gap-y-3 w-[91%] sm:w-[70%]">
                    <h2 className="font-bold text-xl smallPhone:text-2xl mostPhone:text-3xl sm:text-4xl xl:text-5xl text-center">Our Customers Love Us</h2>
                    <p className="font-semibold text-xs smallPhone:text-sm mostPhone:text-base sm:text-xl xl:text-2xl text-center opacity-60">
                        Trusted by engineers and purchasing leaders at the world's most successful companies.</p>
                </div>
                {/* customer images  */}
                <div className="flex flex-wrap gap-x-[1rem] sm:gap-x-[3rem] gap-y-[2rem] sm:gap-y-[4rem] justify-center items-center">
                    {
                        CompanyLogosData?.map((data) => (
                            <img key={data?.id} src={data?.link} alt={data?.alt} loading="lazy" className={`${data?.w} ${data?.h}`} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}




