import React from "react";
import aboutPng from '../../Assets/About/about.png'
import aboutJpg from '../../Assets/About/about.jpg'
import aboutAvif from '../../Assets/About/about.avif'
import aboutWebp from '../../Assets/About/about.webp'

export function AboutImage(){
    return(
        <picture>
        <source srcSet={aboutWebp} type="image/webp"/>
        <source srcSet={aboutAvif} type="image/avif"/>
        <source srcSet={aboutJpg} type="image/jpeg"/>
        <img
            src={aboutPng} // Fallback image if the browser does not support any of the above formats
            alt="fire background"
            loading="lazy"
        />
    </picture>
    )
}