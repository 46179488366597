import { Link } from 'react-router-dom';
import companylogo from '../Assets/companylogo.png'
import NavbarData from '../Data/NavbarData'
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { RxHamburgerMenu } from "react-icons/rx";

export default function Navbar({setShowSideBar,showSideBar}) {

    return (
        <div className='bg-black bg-opacity-[0.3] backdrop-blur-sm py-[0.6rem]'>
            <nav className='mx-auto max-w-[1200px] flex justify-between max-sm:px-[1rem] sm:px-[1rem]'>
                {/* company logo */}
                <div className='flex flex-col gap-y-[0.5rem] justify-center items-center'>
                    <img src={companylogo} alt="company logo" loading='lazy' 
                    className='w-[7vw] mostPhone:w-[2rem] smallDesktop:w-[4rem] bg-white rounded-full' />
                    <div className='font-extrabold text-[1vh] mostPhone:text-xs smallDesktop:text-sm text-white text-center'>Dhananjay Enterprises</div>
                </div>

                {/* navigate section */}
                <div className='flex justify-center max-md:hidden md:visible'>
                    <div
                        className='flex items-start mostPhone:gap-x-[1rem] mostPhone:text-xs smallDesktop:text-base smallDesktop:gap-x-[2rem] text-white font-bold pt-[1.2rem] px-[2rem]'>
                        {
                            NavbarData?.map((content) => (
                                content?.navigate === '/AboutUs' || content?.navigate === '/ClientReview' ?
                                    <Link to={content?.navigate} key={content?.index} className='border-b-2 border-orangeTheme pl-[5px] pr-[7px] cursor-pointer'>{content?.data}</Link>
                                    : <AnchorLink href={content?.navigate} key={content?.index}>
                                        <div className='border-b-2 border-orangeTheme pl-[5px] pr-[7px] cursor-pointer'>{content?.data}</div>
                                    </AnchorLink>
                            ))
                        }
                    </div>
                </div>

                {/* contact button  */}
                <AnchorLink href="#contact" className='flex items-center'>
                    <button className='max-md:hidden md:visible mostPhone:text-xs smallDesktop:text-base text-white bg-orangeTheme font-bold px-8 py-2 rounded-tl-full rounded-bl-full rounded-tr-full rounded-br-full self-center'>
                        Contact
                    </button>
                </AnchorLink>

                {/* Hamburger  */}
                <RxHamburgerMenu className='md:hidden text-white self-center cursor-pointer text-[5vw] mostPhone:text-4xl' 
                onClick={() => setShowSideBar(!showSideBar)}/>

            </nav>
        </div>
    )
}