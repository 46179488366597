import { FiFacebook } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa";
import { FiLinkedin } from "react-icons/fi";
import { AiOutlineYoutube } from "react-icons/ai";
import { IoCallOutline } from "react-icons/io5";

const FooterLogoData = [
    {
        Icon:FiFacebook,
        href:'https://www.facebook.com',
    },
    {
        Icon:FaInstagram,
        href:'https://www.instagram.com',
    },
    {
        Icon:FiLinkedin,
        href:'https://www.linkedin.com',
    },
    {
        Icon:AiOutlineYoutube,
        href:'https://www.youtube.com',
    },
    {
        Icon:IoCallOutline,
        href:'tel:9922941974',
    },
]

export default FooterLogoData;