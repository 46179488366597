import accounts from "../Assets/ExperienceImages/accounts.png"
import handshake from "../Assets/ExperienceImages/handshake.png"
import employee from "../Assets/ExperienceImages/employee.png"

const ExperienceData = [
    {
        img:accounts,
        title:'Customer',
        id:1,
        data:10,
        mediaQuery:'zeroScreen:w-[1.5rem] zeroScreen:h-[1.1rem] smallPhone:w-[2rem] smallPhone:h-[1.5rem] mostPhone:w-[3rem] mostPhone:w-[2rem] sm:w-[5rem] sm:h-[3rem] smallDesktop:w-[7.375rem] smallDesktop:h-[4.875rem]'
    },
    {
        img:handshake,
        title:'Industry Experience',
        id:2,
        data:20,
        mediaQuery:'zeroScreen:w-[1.6rem] zeroScreen:h-[1.2rem] smallPhone:w-[2rem] smallPhone:h-[1.5rem] mostPhone:w-[3rem] mostPhone:w-[2rem] sm:w-[5rem] sm:h-[3rem] smallDesktop:w-[7.438rem] smallDesktop:h-[5rem]'
    },
    {
        img:employee,
        title:'No of Employees',
        id:3,
        data:150,
        mediaQuery:'zeroScreen:w-[1.6rem] zeroScreen:h-[1.5rem] smallPhone:w-[2rem] smallPhone:h-[1.8rem] mostPhone:w-[2.5rem] mostPhone:w-[2rem] sm:w-[3.5rem] sm:h-[3.5rem] smallDesktop:w-[5.563rem] smallDesktop:h-[5.563rem]'
    },
]

export default ExperienceData;