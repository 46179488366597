import React from 'react'

export default function Box(props) {
    return (
        <div className={`z-20 flex flex-col justify-center zeroScreen:absolute mostPhone:static ${props?.showCard ? 'zeroScreen:scale-100' : 'zeroScreen:scale-0'}
        mostPhone:scale-100 zeroScreen:h-[6rem] zeroScreen:w-[7rem] smallPhone:h-[8rem] smallPhone:w-[9rem] sm:h-[10rem] sm:w-[11rem] smallDesktop:h-60 smallDesktop:w-60 
        rounded-3xl bg-[#FF5B36] bg-opacity-[0.9]`}>
            <div className="flex items-center justify-center">
                <img src={props?.img} alt={props?.title} loading='lazy' className={props?.mediaQuery}/>
            </div>
            <div className="text-center text-white zeroScreen:p-[0.4rem] smallPhone:p-3">
                <p className="zeroScreen:text-[10px] smallPhone:text-[9px] mostPhone:text-sm sm:text-base smallDesktop:text-2xl">
                    <span className="font-bold smallPhone:text-xs sm:text-3xl smallDesktop:text-5xl">{props?.data}+</span> <br />
                    {props?.title}
                </p>
            </div>
        </div>
    )
}
