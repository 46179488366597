import React from 'react'
import ExpBox from '../../Components/Experience/ExpBox'
import equipments from "../../Assets/ExperienceImages/equipments.png";
import experiencesectionbottomdesign from '../../Assets/experiencesectionbottomdesign.png'

export default function ExperienceSection() {
    return (
        <div className='mt-[4rem]'>
            <div className="relative zeroScreen:h-[9rem] smallPhone:h-[12rem] mostPhone:h-[16rem] sm:h-[20rem] smallDesktop:h-[25rem] flex justify-center items-center">
                <img
                    src={equipments}
                    alt="fire background"
                    loading="lazy"
                    className="absolute w-full h-[100%] z-0 filter brightness-[0.4]"
                />
                <ExpBox />
            </div>
            <img src={experiencesectionbottomdesign} alt='background' loading='lazy'/>
        </div>
    )
}
