import React, { useState } from 'react'
import Box from './Box'
import ExperienceData from '../../Data/ExperiencesData'
import { RiArrowRightSLine } from "react-icons/ri";
import { RiArrowLeftSLine } from "react-icons/ri";

export default function ExpBox() {
    const [showCard,setShowCard] = useState([false,true,false,false]);
    const [cardIndex,setCardIndex] = useState(1);

    // handle change card 
    const handleChangeCard = (moveFront) => {
        const oldShowCardArray = [...showCard];
        oldShowCardArray[cardIndex] = false;
        let oldCardIndex = cardIndex ;
        if(moveFront){
            oldCardIndex++ ;
            if(oldCardIndex > 3) oldCardIndex = 1 ;
        }
        else{
            oldCardIndex-- ;
            if(oldCardIndex <= 0) oldCardIndex = 3 ;
        }
        oldShowCardArray[oldCardIndex] = true ;
        setCardIndex(oldCardIndex);
        setShowCard(oldShowCardArray);
    }

    return (
        <div className="flex justify-center items-center mostPhone:flex-row 
        zeroScreen:gap-x-2 sm:gap-x-6 smallDesktop:gap-x-10 opacity-85">
            <RiArrowLeftSLine onClick={() => handleChangeCard(true)} className='zeroScreen:visible mostPhone:hidden absolute text-white zeroScreen:-ml-[9rem]
            smallPhone:-ml-[11rem] zeroScreen:text-[1.3rem] smallPhone:text-[3rem]'/>
            {
                ExperienceData?.map((data) => (
                    <Box key={data?.id} img={data?.img} title={data?.title} data={data?.data} mediaQuery={data?.mediaQuery} showCard={showCard[data?.id]}/>
                ))
            }
            <RiArrowRightSLine onClick={() => handleChangeCard(false)} className='zeroScreen:visible mostPhone:hidden absolute text-white zeroScreen:-mr-[9rem]
            smallPhone:-mr-[11rem] zeroScreen:text-[1.3rem] smallPhone:text-[3rem]'/>
        </div> 
    )
}
