import herosectionbgPng from '../../Assets/HeroSectionBg/herosectionbg.png'
import herosectionbgWebP from '../../Assets/HeroSectionBg/herosectionbg.webp'
import herosectionbgAviF from '../../Assets/HeroSectionBg/herosectionbg.avif'
import herosectionbgJPG from '../../Assets/HeroSectionBg/herosectionbg.jpg'
import Navbar from '../../Components/Navbar'
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoArrowUp } from "react-icons/io5";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useState } from 'react';
import SideNavbar from '../../Components/SideNavbar';
import '../../index.css'

export default function HeroSection() {
    const [showSideBar,setShowSideBar] = useState(false);

    return (
        <div id='home' className='w-[100vw] zeroScreen:h-[60vh] smallDesktop:h-[90vh] relative'>

        <picture>
            <source srcSet={herosectionbgWebP} type="image/webp"/>
            <source srcSet={herosectionbgAviF} type="image/avif"/>
            <source srcSet={herosectionbgJPG} type="image/jpeg"/>
            <img
                src={herosectionbgPng} // Fallback image if the browser does not support any of the above formats
                alt="fire background"
                loading="lazy"
                className='max-md:w-[100%] md:w-[100%] object-cover h-[100%] absolute filter brightness-[0.39]'
            />
        </picture>

            <div className='absolute z-10 w-[100%] h-[85%]'>
                {/* Navbar  */}
                <Navbar showSideBar={showSideBar} setShowSideBar={setShowSideBar}/>
                <div className='text-white h-full w-full flex flex-col justify-center items-center gap-y-16'>
                    <div className='flex flex-col justify-center items-center mostPhone:gap-y-4 smallDesktop:gap-y-6'>
                        <h1 className='font-black text-center text-[6vw] smallPhone:text-2xl mostPhone:text-3xl sm:text-5xl
                        smallDesktop:text-6xl largeDevice:text-7xl'>
                            Dhananjay Enterprises</h1>
                        <h3 className='font-bold text-center text-[4vw] smallPhone:text-sm mostPhone:text-lg sm:text-2xl
                         smallDesktop:text-3xl largeDevice:text-4xl'>
                            Making The World A <span className='text-orangeTheme'>Safer</span> Place</h3>
                    </div>
                    <AnchorLink href='#services' className="flex flex-col justify-center items-center opacity-50 font-semibold cursor-pointer">
                        <p className='text-[3vw] smallPhone:text-sm sm:text-xl smallDesktop:text-2xl'>View More</p>
                        <MdKeyboardArrowDown className='text-[3vw] mostPhone:text-2xl smallDesktop:text-4xl' />
                    </AnchorLink>
                </div>
                <div className='px-[1rem] -mt-[3rem] fixed right-0 cursor-pointer'>
                    <AnchorLink href="#home" className='bg-gray-300 inline-block bg-opacity-[0.4] p-[1vw] mostPhone:p-[1rem] rounded-xl'>
                        <IoArrowUp className='text-[7vw] mostPhone:text-2xl smallDesktop:text-4xl' />
                    </AnchorLink>
                </div>
            </div>

            {/* Sidebar menu for small Screen  */}
            <div className={`${showSideBar ? 'w-[40vw]' : 'w-[0]'} md:hidden
             absolute bottom-0 left-0 bg-black bg-opacity-[0.3] backdrop-for-safari-browser
            overflow-hidden h-[100%] z-[15] transition-all duration-500 ease-in-out
             ${showSideBar ? 'p-4' : 'p-0'} flex flex-col rounded-r-[2.375rem]`}>
                <SideNavbar/>
            </div>
        </div>
    )
}