import React from "react";
import rightquote from '../../Assets/rightquote.png'
import { FaStar, FaRegStar} from "react-icons/fa";

export function Card(props) {
    const startIcons = Array.from({ length: 5 }, (_, index) => (
        ((index + 1) * 2) <= props?.rating ? 
        (<FaStar key={index} className="text-yellow-400 text-[3vw] mostPhone:text-[1rem] smallDesktop:text-[1.3rem] lg:text-[1.5rem]"/>)
        :
        <FaRegStar key={index} className="text-yellow-400 text-[3vw] mostPhone:text-[1rem] smallDesktop:text-[1.3rem] lg:text-[1.5rem]" />
    ))
    
    return (
        <div className={`${props?.showCard ? 'zeroScreen:scale-100' : 'zeroScreen:scale-0'} smallDesktop:scale-100 zeroScreen:absolute 
        smallDesktop:static flex justify-center gap-1 top-[1rem]  w-[10rem] h-[11rem] smallPhone:w-[14rem] smallPhone:h-[13rem] 
        mostPhone:w-[16rem] mostPhone:h-[15rem] lg:w-[18rem] lg:h-[17rem] xl:w-[23rem] xl:h-[22.125rem]`}>
            <div className="border py-8 px-2 w-[23rem] h-40vh" style={{ backgroundColor: `${props.bg}` }}>
                <div className="flex justify-center">
                    <img src={rightquote} alt="quote" loading="lazy" className="h-[4vw] mostPhone:h-[1.5rem] smallDesktop:h-[2rem]"/>
                </div>
                <h1 className={`${props?.bg === '#3A3A3A' ? 'text-[#E9E9E9]' : 'text-[#3A3A3A]'} text-[4vw] mostPhone:text-[1rem] 
                text-center mt-[1rem] font-bold`}>{props.name}</h1>
                <p className="zeroScreen:mt-[0.2rem] smallDesktop:mt-[0.7rem] text-[1.8vw] mostPhone:text-sm pl-2" 
                style={{ color: `${props.color}` }}>{props.desc}</p>
                <div className="flex justify-center zeroScreen:mt-2 smallDesktop:mt-4 lg:mt-8" style={{ color: '#FFD700' }}>
                    {startIcons}
                </div>
                <div className="flex justify-center align-center translate-y-14">
                    <img src={props.img} className="object-contain rounded-full w-[10vw] h-[10vw]
                    mostPhone:w-[5rem] mostPhone:h-[5rem] xl:w-[7rem] xl:h-[7rem] -translate-y-[3rem] 
                    smallDesktop:-translate-y-[3.5rem] lg:-translate-y-[3rem] xl:translate-y-8" alt="reviews_image" />
                </div>
            </div>
        </div>
    );
}