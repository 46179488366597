const OverallExperienceData = [
    {
        data:1,
        id:1,
    },
    {
        data:2,
        id:2,
    },
    {
        data:3,
        id:3,
    },
    {
        data:4,
        id:4,
    },
    {
        data:5,
        id:5,
    },
    {
        data:6,
        id:6,
    },
    {
        data:7,
        id:7,
    },
    {
        data:8,
        id:8,
    },
    {
        data:9,
        id:9,
    },
    {
        data:10,
        id:10,
    },
]

export default OverallExperienceData;