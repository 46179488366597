import atharvaengineering from '../Assets/CompanyLogos/Atharva Engineering.png'
import bhateandraje from '../Assets/CompanyLogos/Bhate and Raje .png'
import graphiteindialimited from '../Assets/CompanyLogos/Graphite India Limited.png'
import onyxengineering from '../Assets/CompanyLogos/Onyx Engineering.gif'
import sconproject from '../Assets/CompanyLogos/Scon project.jpeg'
import solarischemtech from '../Assets/CompanyLogos/Solaris Chemtech.jpeg'
import thefiredynamic from '../Assets/CompanyLogos/The Fire Dynamics.jpeg'
import ultrafiretech from '../Assets/CompanyLogos/Ultra firetech .avif'

const CompanyLogosData = [
    {
        link:atharvaengineering,
        id:1,
        alt:'Atharva Engineering',
        w:'w-[5rem] sm:w-[8rem] lg:w-[10rem] xl:w-[11rem]',
        h:'h-[4rem] sm:h-[6rem] lg:h-[8rem] xl:h-[9rem]'
    },
    {
        link:sconproject,
        id:2,
        alt:'Scon Projects',
        w:'w-[4rem] sm:w-[6rem] lg:w-[8rem] xl:w-[9rem]',
        h:'h-[5rem] sm:h-[6rem] lg:h-[8rem] xl:h-[9rem]'
    },
    {
        link:onyxengineering,
        id:3,
        alt:'Onyx Engineering',
        w:'w-[5rem] sm:w-[7rem] lg:w-[11rem] xl:w-[12rem]',
        h:'h-[2rem] sm:h-[3rem] lg:h-[5rem] xl:h-[6rem]'
    },
    {
        link:solarischemtech,
        id:4,
        alt:'Solaris Chemtech',
        w:'w-[6rem] sm:w-[9rem] lg:w-[11rem] xl:w-[12rem]',
        h:'h-[6rem] sm:h-[7rem] lg:h-[9rem] xl:h-[10rem]'
    },
    {
        link:graphiteindialimited,
        id:5,
        alt:'Graphite India Limited',
        w:'w-[7rem] sm:w-[13rem] lg:w-[15rem] xl:w-[16rem]',
        h:'h-[4rem] sm:h-[6rem] lg:h-[8rem] xl:h-[9rem]'
    },
    {
        link:thefiredynamic,
        id:6,
        alt:'The Fire Dynamic',
        w:'w-[7rem] sm:w-[13rem] lg:w-[17rem] xl:w-[18rem]',
        h:'h-[3rem] sm:h-[4rem] lg:h-[6rem] xl:h-[7rem]'
    },
    {
        link:bhateandraje,
        id:7,
        alt:'Bhate And Raje',
        w:'w-[6rem] sm:w-[13rem] lg:w-[17rem] xl:w-[18rem]',
        h:'h-[3rem] sm:h-[4rem] lg:h-[6rem] xl:h-[7rem]'
    },
    {
        link:ultrafiretech,
        id:8,
        alt:'Ultra Firetech Systems',
        w:'w-[7rem] sm:w-[13rem] lg:w-[17rem] xl:w-[18rem]',
        h:'h-[3rem] sm:h-[4rem] lg:h-[6rem] xl:h-[7rem]'
    },
]

export default CompanyLogosData;