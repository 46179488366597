import founderimg from '../../Assets/founderimg.png'

export default function FounderSection(){
    return(
        <div className='p-[1rem] smallPhone:px-[3rem] smallPhone:py-[2rem] md:px-[6rem] md:py-[4rem] xl:px-[10rem] xl:py-[5rem]'>
            <div className="bg-[#F7E7D8] rounded-3xl py-[2rem] smallPhone:py-[3rem] pt-[4rem] smallPhone:pt-[5rem] flex flex-col justify-center items-center gap-y-[1rem]">
                <div className='flex justify-center'>
                    <div className='relative bg-[#14FFB7] w-[8rem] h-[5rem] smallPhone:w-[10rem] smallPhone:h-[8rem] sm:w-[13rem] sm:h-[10rem] md:w-[16rem] md:h-[14rem] smallDesktop:w-[20rem] smallDesktop:h-[18rem] border-[1px] border-black rounded-lg'>
                        <img src={founderimg} alt="Founder" loading="lazy" className='absolute w-[8rem] h-[10rem] smallPhone:w-[10rem] smallPhone:h-[13rem] sm:w-[13rem] sm:h-[17rem] md:w-[20rem] md:h-[21rem] smallDesktop:h-[25rem] -top-[5rem] sm:-top-[7rem]'/>
                    </div>
                </div>
                <div className='flex flex-col justify-center items-center'>
                    <h1 className='font-bold text-[0.8rem] smallPhone:text-[1rem] sm:text-2xl'>Dhananjay Singh</h1>
                    <p className='font-semibold text-[0.6rem] smallPhone:text-[0.8rem] sm:text-[1rem]'>Founder & CEO</p>
                </div>
            </div>
        </div>
    )
}