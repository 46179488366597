import G1 from "../Assets/GalleryImages/G1.png"
import G2 from "../Assets/GalleryImages/G2.png"
import G3 from "../Assets/GalleryImages/G3.png"
import G4 from "../Assets/GalleryImages/G4.png"
import G5 from "../Assets/GalleryImages/G5.png"
import G6 from "../Assets/GalleryImages/G6.png"
import G7 from "../Assets/GalleryImages/G7.png"
import G8 from "../Assets/GalleryImages/G8.png"
import G9 from "../Assets/GalleryImages/G9.png"
import G10 from "../Assets/GalleryImages/G10.png"

const galleryImages = [
    {
        link:G1,
        id:1,
        alt:'image1',
    },
    {
        link:G2,
        id:2,
        alt:'image2',
    },
    {
        link:G3,
        id:3,
        alt:'image3',
    },
    {
        link:G4,
        id:4,
        alt:'image4',
    },
    {
        link:G5,
        id:5,
        alt:'image5',
    },
    {
        link:G6,
        id:6,
        alt:'image6',
    },
    {
        link:G7,
        id:7,
        alt:'image7',
    },
    {
        link:G8,
        id:8,
        alt:'image8',
    },
    {
        link:G9,
        id:9,
        alt:'image9',
    },
    {
        link:G10,
        id:10,
        alt:'image10',
    },
]

export default galleryImages;