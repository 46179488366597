import React from 'react'
import galleryImages from "../../Data/GalleryImagesData.js";

export default function GalleryScroll() {
    return (
        <div className="w-full zeroScreen:w-[70%] mostPhone:w-[100%] sm:w-[90%] smallDesktop:w-[80%] largeDevice:w-[50%]
        zeroScreen:max-h-[14rem] smallPhone:max-h-[24rem] largeDevice:max-h-[27.5rem] scrollbar-hide overflow-y-scroll">
            <div className="flex flex-wrap gap-x-[0.5rem] w-[100%] gap-y-[0.5rem] justify-center">
                {
                    galleryImages?.map((data) => (
                        <img key={data?.id} src={data?.link} alt={data?.alt} loading="lazy" className='mostPhone:w-[82%] sm:w-[45%]' />
                    ))
                }
            </div>
        </div>
    )
}
