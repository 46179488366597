const NavBarData = [
    {
        data:"Home",
        index:1,
        navigate:'#home'
    },
    {
        data:"About Us",
        index:2,
        navigate:'/AboutUs'
    },
    {
        data:"Services",
        index:3,
        navigate:'#services',
    },
    {
        data:"Industry Serve",
        index:4,
        navigate:'#industryserve'
    },
    {
        data:"Review",
        index:5,
        navigate:'/ClientReview'
    }
];

export default NavBarData;