import containerizedfirepumpsystem from '../Assets/Services/containerizedfirepumpsystem.png'
import firealarms from '../Assets/Services/firealarms.png'
import coolingtowerpiping from '../Assets/Services/coolingtowerpiping.png'
import compressedair from '../Assets/Services/compressedair.png'
import containeronrentandsupply from '../Assets/Services/containeronrentandsupply.png'
import firesuspensionsystem from '../Assets/Services/firesuspensionsystem.png'
import gaspiping from '../Assets/Services/gaspiping.png'
import hvacpiping from '../Assets/Services/hvacpiping.png'
import materialstoragecontainer from '../Assets/Services/materialstoragecontainer.png'
import materialstoragecontainerwithoffice from '../Assets/Services/materialstoragecontainerwithoffice.png'
import officecontainer from '../Assets/Services/officecontainer.png'
import sprinkelsystem from '../Assets/Services/sprinkelsystem.png'
import sspiping from '../Assets/Services/sspiping.png'

const ServicesData = [
    {
        link:containerizedfirepumpsystem,
        id:1,
        heading:'Containerized Fire Pump Systems',
        description:`Constructed inside a standard shipping container, the units are easy 
        to ship and install. Fast installation with minimal labour makes it a perfect retrofit 
        solution. No on-site assembly labor or specialized tools are required. Installation requires 
        only that you connect the piping and the power supply.`
    },
    {
        link:firealarms,
        id:2,
        heading:'Fire Alarms',
        description:`Fire alarms detect fires using sensors for smoke, heat, or carbon monoxide, 
        triggering alarms. Types include ionization, photoelectric, and combination detectors. 
        They integrate with building management for monitoring and response.`
    },
    {
        link:sprinkelsystem,
        id:3,
        heading:'Sprinkler Systems',
        description:`Sprinkler systems use water to control fires with pipes, heads, and valves. 
        When heat triggers a sprinkler head, water releases onto the fire. These systems safeguard 
        lives and property in various settings.`
    },
    {
        link:firesuspensionsystem,
        id:4,
        heading:'Fire Suspension System',
        description:`The Fire Suspension System swiftly detects and extinguishes fires using
        advanced agents like dry chemicals, foam, or inert gases, widely employed across 
        industries to mitigate risks in diverse environments.`
    },
    {
        link:coolingtowerpiping,
        id:5,
        heading:'Cooling Tower Piping',
        description:`Cooling tower piping: supply, return lines circulate water with valves, 
        pumps for efficient flow, heat exchange. Layout minimizes pressure drop, maximizes cooling.`
    },
    {
        link:compressedair,
        id:6,
        heading:'Compressed Air',
        description:`Compressed air  gas under pressure used for power tools, pneumatic systems; 
        generated by compressors, stored in tanks for various industrial applications.`
    },
    {
        link:containeronrentandsupply,
        id:7,
        heading:'Container On Rent And Supply',
        description:`Container rental and supply: Offers flexible leasing options for containers, 
        facilitating storage, shipping needs; available in various sizes, configurations to meet diverse requirements.`
    },
    {
        link:gaspiping,
        id:8,
        heading:'Gas Piping',
        description:`Gas piping: Distributes natural gas/propane for heating, cooking; requires safe installation, 
        maintenance for efficiency, compliance.`
    },
    {
        link:hvacpiping,
        id:9,
        heading:'HVAC Piping',
        description:`HVAC piping Circulates water, refrigerants for heating, ventilation, air conditioning; 
        critical for temperature control, requires precise design, maintenance.`
    },
    {
        link:materialstoragecontainerwithoffice,
        id:10,
        heading:'Material Storage Container With Office',
        description:`Material storage: Organized systems optimize space, streamline inventory management 
        for goods, utilizing racks, bins, shelves, ensuring accessibility, efficiency in industrial, 
        commercial settings.`
    },
    {
        link:materialstoragecontainer,
        id:11,
        heading:'Material Storage Container',
        description:`Material storage containers: Versatile units for storing goods, equipment; available 
        in various sizes, configurations to meet diverse storage needs, adaptable for construction sites, 
        warehouses.`
    },
    {
        link:officecontainer,
        id:12,
        heading:'Office Container',
        description:`Office containers  Modular units offer flexible workspaces with electricity, HVAC, 
        furnishings for construction sites, events, or rapid office expansion.`
    },
    {
        link:sspiping,
        id:13,
        heading:'SS Piping',
        description:`SS piping  Stainless steel ensures corrosion resistance, durability for conveying 
        liquids, gases in pharmaceuticals, food processing, meeting strict purity standards.`
    },
]

export default ServicesData;