import React, { useEffect, useState } from "react";
import { Card } from "../../Components/Testimonials/Cards";
import { RiArrowRightSLine } from "react-icons/ri";
import { RiArrowLeftSLine } from "react-icons/ri";
import CLIENT_REVIEW_URL from "../../API_URL/ClientReview";
import { toast } from "react-toastify";
import axios from "axios";

export default function Testimonials() {
    const [showCard, setShowCard] = useState([false, true, false, false]);
    const [cardIndex, setCardIndex] = useState(1);
    const [clientReviewData,setClientReviewData] = useState([]);

    useEffect(() => {
        const fetchClientReviewData = async() => {
            try{
                const response = await axios.get(CLIENT_REVIEW_URL);
                
                if(response?.data?.success){

                    // Picking only the top 3 card data 
                    let respData = [] ; 
                    for(let i = 0 ; i < 3 ; i++){
                        respData[i] = response?.data?.data[i];
                        respData[i].bg = (i % 2 === 0 ? "#3A3A3A" : "#E9E9E9");
                        respData[i].color = (i % 2 === 0 ? "white" : "Black"); 
                    }
                    setClientReviewData(respData);
                    toast.success(response?.data?.message);
                }
                else toast.error(response?.data?.message);
            }catch(error){
                toast.error("An error occurred while fetching client review data")
            }
        }
        fetchClientReviewData();
    },[]);

    // handle change card 
    const handleChangeCard = (moveFront) => {
        const oldShowCardArray = [...showCard];
        oldShowCardArray[cardIndex] = false;
        let oldCardIndex = cardIndex;
        if (moveFront) {
            oldCardIndex++;
            if (oldCardIndex > 3) oldCardIndex = 1;
        }
        else {
            oldCardIndex--;
            if (oldCardIndex <= 0) oldCardIndex = 3;
        }
        oldShowCardArray[oldCardIndex] = true;
        setCardIndex(oldCardIndex);
        setShowCard(oldShowCardArray);
    }
    return (
        <div className="mt-[1rem] smallPhone:mt-[2rem] smallDesktop:mt-[6rem] flex flex-col gap-y-[1rem] sm:gap-y-[2rem] smallDesktop:gap-y-[5rem] 
        lg:gap-y-[7rem] xl:gap-y-[10rem] w-[100vw] min-h-[14rem] mostPhone:min-h-[16rem] sm:min-h-[18rem] smallDesktop:min-h-[8rem]">
            <h1 className="px-[1rem] text-wrap text-lg smallPhone:text-xl mostPhone:text-2xl sm:text-3xl smallDesktop:text-4xl xl:text-5xl text-center font-bold">What
                <span className="text-orangeTheme"> Client</span> Says About Us</h1>
            <div className="relative flex justify-center items-center gap-x-[4rem] w-[100%]">
                <RiArrowLeftSLine onClick={() => handleChangeCard(true)} className='zeroScreen:visible smallDesktop:hidden absolute text-black zeroScreen:-ml-[9rem]
                smallPhone:-ml-[11rem] text-[2rem] mostPhone:text-[3rem] -translate-x-[1.5rem] translate-y-[7rem] smallPhone:-translate-x-[3rem]
                mostPhone:-translate-x-[5rem] mostPhone:translate-y-[8rem]'/>
                {clientReviewData?.map((data, index) => (
                    <Card
                        key={index + 1}
                        name={data?.Name + " " + data?.Surname}
                        img={data?.profilePic}
                        bg={data?.bg}
                        desc={data?.Description}
                        rating={data?.Rating}
                        color={data?.color}
                        showCard={showCard[index + 1]}
                    ></Card>
                ))}
                <RiArrowRightSLine onClick={() => handleChangeCard(false)} className='zeroScreen:visible smallDesktop:hidden absolute text-black zeroScreen:-mr-[9rem]
                smallPhone:-mr-[11rem] text-[2rem] mostPhone:text-[3rem] translate-x-[1.5rem] translate-y-[7rem] smallPhone:translate-x-[3rem] mostPhone:translate-x-[4.7rem]
                mostPhone:translate-y-[8rem]'/>
            </div>
        </div>
    );
}
