import Footer from "../Components/Footer"
import HeroSection from "../Sections/HomePageSections/HeroSection"
import AboutSection from "../Sections/HomePageSections/AboutSection"
import Contact from "../Components/Contact/Contact"
import Testimonials from "../Sections/HomePageSections/TestimonialsSections"
import OurCustomers from "../Sections/HomePageSections/OurCustomers"
import Services from "../Sections/HomePageSections/ServicesSection"
import ExperienceSection from "../Sections/HomePageSections/ExperienceSection"
import GallerySection from "../Sections/HomePageSections/GallerySection"


export default function HomePage(){
    return(
        <div>
            <HeroSection/>
            <Services/>
            <ExperienceSection/>
            <GallerySection/>
            <AboutSection/>
            <Testimonials/>
            <Contact/>
            <OurCustomers/>
            <Footer/>
        </div>
    )
};