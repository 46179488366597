import CustomerReviewData from '../Data/CustomerReviewData';
import OverallExperienceData from '../Data/OverallExperienceData';
// import clientreviewpagebg from '../Assets/clientreviewpagebg.png';
import clientreviewpagebgPng from '../Assets/ClientReviewPageBg/clientreviewpagebg.png'
import clientreviewpagebgJpg from '../Assets/ClientReviewPageBg/clientreviewpagebg.jpg'
import clientreviewpagebgAvif from '../Assets/ClientReviewPageBg/clientreviewpagebg.avif'
import clientreviewpagebgWebp from '../Assets/ClientReviewPageBg/clientreviewpagebg.webp'
import { Link } from 'react-router-dom';
import { IoArrowUp } from "react-icons/io5";
import { useState } from 'react';
import {useForm} from 'react-hook-form';
import FEEDBACK_REVIEW_URL from '../API_URL/FeedbackReview';
import axios from 'axios'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ClientReviewPage() {
    const [OverallExperienceDataColor,setOverallExperienceDataColor] = useState(Array.from({length:10},() => 0));
    const [recommendChoice,setRecommendChoice] = useState([0,0]);

    const {register, handleSubmit, formState: { errors }, reset} = useForm();

    // Changing background of rating 
    const rateHandler = (id) => {
        let newExpDataColor = Array.from({length:10},() => 0);
        for(let i = 0 ; i < id ; i++){
            newExpDataColor[i] = 1 ;
        }
        setOverallExperienceDataColor(newExpDataColor);
    }

    // Changing background of recommend choice yes or no
    const recommendChoiceHandler = (id) => {
        let newRecoChoice = [0,0];
        newRecoChoice[id] = 1 ;
        setRecommendChoice(newRecoChoice);
    }

    // Reseting form data 
    const resetFormData = () => {
        reset();
        let newExpDataColor = Array.from({length:10},() => 0);
        setOverallExperienceDataColor(newExpDataColor);
        let newRecoChoice = [0,0];
        setRecommendChoice(newRecoChoice);
    }

    // Submitting form data to backend
    const onSubmit = async(data) => {
        try{
            // Validating form to check all fields are filled 
            if(!OverallExperienceDataColor[0] || (recommendChoice[0] === recommendChoice[1])){
                toast.warn("Please fill all detail");
                return ;
            }
            // Putting rating into data
            for(let i = 9 ; i >= 0 ; i--){
                if(OverallExperienceDataColor[i]){
                    data.Rating = i + 1 ;
                    break ;
                }
            }

            // Putting recommend into data
            data.Recommend = recommendChoice[0] ? "Yes" : "No" ;

            const response = await axios.post(FEEDBACK_REVIEW_URL,data);
            
            if(response?.data?.success){
                toast.success(response?.data?.message);
                resetFormData();
            }
            else toast.error(response?.data?.message);

        }catch(error){
            toast.error("An error occurred while processing your request")
        }
    }

    return (
        <div className='w-screen h-auto min-h-[100vh] pb-[1rem] bg-cover text-white' style={{ backgroundImage: `url(${clientreviewpagebgWebp}), 
        url(${clientreviewpagebgAvif}), url(${clientreviewpagebgJpg}), url(${clientreviewpagebgPng})` }}>
            <div className='w-[100%] pt-[1rem] smallPhone:pt-[2rem] pr-[1rem] mostPhone:pt-[2rem] mostPhone:pr-[2rem] smallDesktop:pt-[3rem] 
            smallDesktop:pr-[4rem] flex flex-row-reverse'>
                <Link to="/" className='rotate-90 bg-opacity-60 bg-white backdrop-blur-lg filter brightness-150 rounded-[2rem] cursor-pointer p-[0.6rem]'>
                    <IoArrowUp className='text-[0.8rem] smallPhone:text-2xl sm:text-4xl text-black' />
                </Link>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className='w-full flex justify-center items-center'>
                <div
                    className='p-[1rem] smallPhone:p-[3rem] bg-opacity-60 backdrop-blur-lg rounded-[2rem] filter brightness-100 
                    bg-[#05296E] border-[1px] border-white flex flex-col gap-y-[2rem] items-center w-[80%] mostPhone:w-[70%] smallDesktop:w-[60%] xl:w-[47%]'>
                    {/* Heading section  */}
                    <div className='flex flex-col items-center gap-y-3 w-[100%]'>
                        <div className='text-center text-xl sm:text-2xl 2xl:text-4xl font-bold'>Client Review Form</div>
                        <p className='text-xs sm:text-sm 2xl:text-md font-semibold text-center'>Thank you for choosing Dhananjay 
                        Enterprises. We'd love to hear feedback from our client. Please fill out the form below</p>
                    </div>
                    {/* client detail section  */}
                    <div className='flex flex-wrap gap-x-[4rem] gap-y-[1rem] justify-center'>
                        {
                            CustomerReviewData?.map((data) => (
                                <label key={data?.id} className='text-center smallPhone:text-start flex flex-col text-sm sm:text-lg 
                                2xl:text-xl font-semibold gap-y-[4px] w-[70%] smallPhone:w-[100%] md:w-[40%]'>
                                    {data?.label}
                                    <input type={data?.type} placeholder={data?.placeholder} {...register(`${data?.name}`, { required: true })} 
                                    className='p-[5px] px-[15px] rounded-3xl text-black' />
                                    {errors[data?.name] && <span className="text-red-500 text-sm">This field is required <sup>*</sup></span>}
                                </label>
                            ))
                        }
                    </div>
                    {/* Experience rating  */}
                    <div className='flex flex-col items-center gap-y-[1rem] w-full'>
                        <p className='text-sm sm:text-lg 2xl:text-xl font-semibold'>Overall Experience : </p>
                        <div className='flex gap-x-[1rem] flex-wrap justify-center gap-y-[1rem]'>
                            {
                                OverallExperienceData?.map((data) => (
                                    <div key={data?.id} onClick={() => rateHandler(data?.id)} 
                                    className={`${OverallExperienceDataColor[data?.id - 1] ? 'bg-orangeTheme' : 'bg-white'} 
                                    text-sm sm:text-lg 2xl:text-xl font-semibold px-[1rem] py-[0.5rem] text-black rounded-full 
                                    cursor-pointer`}>{data?.data}</div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='flex flex-col smallDesktop:flex-row gap-x-[1rem] gap-y-[0.5rem] items-center text-center'>
                        <p className='text-sm sm:text-lg 2xl:text-xl font-semibold'>Would You Recommend Us to a friend : </p>
                        <div className='flex text-sm sm:text-lg 2xl:text-xl font-semibold gap-x-[1rem]'>
                            <div onClick={() => recommendChoiceHandler(0)} className={`${recommendChoice[0] ? 'bg-orangeTheme' : 'bg-white'} 
                            text-black py-[0.1rem] px-[1rem] rounded-3xl cursor-pointer`}>Yes</div>
                            <div onClick={() => recommendChoiceHandler(1)} className={`${recommendChoice[1] ? 'bg-orangeTheme' : 'bg-white'} 
                            text-black py-[0.1rem] px-[1rem] rounded-3xl cursor-pointer`}>No</div>
                        </div>
                    </div>
                    {/* Comment  */}
                   <div className='w-full'>
                        <textarea rows="4" placeholder='Comments' {...register("Description", { required: true })} 
                        className='w-full rounded-md text-sm sm:text-lg 2xl:text-xl p-2 text-black' />
                        {errors.Description && <span className="text-red-500 text-sm">This field is required <sup>*</sup></span>}
                   </div>
                    <button className='bg-[#001A5C] w-full p-2 rounded-3xl text-sm sm:text-lg 2xl:text-xl'>Submit</button>
                </div>
            </form>
        </div>
    )
}
