import React from "react";
import {useForm} from 'react-hook-form';

export function Form() {
    const {register, handleSubmit, formState: { errors },} = useForm();

    // Sending message to whatsapp
    const onSubmit = (data) => {
        const {Name, Surname, Email, Mobile, Description} = data ;
        let whatappUrl = "https://wa.me/9922941974?text=" 
        + "Name of Client : " + Name + " " + Surname + "%0a"
        + "Mobile No : " + Mobile + "%0a"
        + "Email ID : " + Email + "%0a"
        + "Message : " + Description;

        window.open(whatappUrl,"_blank").focus();
    }

    return (
        <div className="p-[1rem] mostPhone:p-[2rem] xl:p-[3rem] rounded-3xl bg-opacity-75 bg-gray-200 w-[90%] mostPhone:w-[64%] 
        md:max-w-[42%] xl:max-w-[42%] flex-1 h-auto">
            <h3 className="text-xl smallPhone:text-2xl smallDesktop:text-3xl xl:text-4xl text-center xl:text-start font-bold">Get in Touch</h3>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col xl:gap-y-[0.8rem]">
                <div className="flex gap-[0.5rem] xl:gap-8 mt-4 flex-wrap justify-center">
                    <div className="flex flex-col">
                        <label htmlFor="Name" className="px-2 text-sm smallDesktop:text-base xl:text-2xl font-normal"> Name</label>
                        <input type="text" placeholder="John" {...register("Name", { required: true })} 
                        className="border mt-2 border-black rounded-full py-1 px-3 text-sm smallDesktop:text-base xl:text-2xl font-normal w-[100%]" />
                        {errors.Name && <span className="text-red-600">This field is required <sup>*</sup></span>}
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="Surname" className="px-2 text-sm smallDesktop:text-base xl:text-2xl font-normal"> Surname</label>
                        <input type="text" placeholder="Doe" {...register("Surname", { required: true })} 
                        className="border mt-2 border-black rounded-full py-1 px-3 text-sm smallDesktop:text-base xl:text-2xl font-normal w-[100%]"/>
                        {errors.Surname && <span className="text-red-600">This field is required <sup>*</sup></span>}
                    </div>
                </div>
                <div className="flex flex-col mt-4">
                    <label htmlFor="Email" className="px-2 text-sm smallDesktop:text-base xl:text-2xl font-normal"> Email</label>
                    <input type="text" placeholder="John.doe@example.com" {...register("Email", { required: true })} 
                    className="border mt-2 border-black rounded-full py-1 px-3 text-sm smallDesktop:text-base xl:text-2xl font-normal" />
                    {errors.Email && <span className="text-red-600">This field is required <sup>*</sup></span>}
                </div>
                <div className="flex flex-col mt-4">
                    <label htmlFor="Mobile" className="px-2 text-sm smallDesktop:text-base xl:text-2xl font-normal"> Mobile No.</label>
                    <input type="text" placeholder="xxxx xxx xxx" pattern="[0-9]*"
                     {...register("Mobile", { required: true })}  className="border mt-2 border-black rounded-full py-1 px-3 
                    text-sm smallDesktop:text-base xl:text-2xl font-normal" />
                    {errors.Mobile && <span className="text-red-600">This field is required <sup>*</sup></span>}
                </div>
                <div className="flex flex-col mt-4">
                    <label htmlFor="Description" className="px-2 text-sm smallDesktop:text-base xl:text-2xl font-normal"> Description</label>
                    <textarea id="" cols="30" rows="5" {...register("Description", { required: true })} 
                    className="border mt-2 border-black rounded-md py-1 px-3 text-sm smallDesktop:text-base xl:text-2xl font-normal"></textarea>
                    {errors.Description && <span className="text-red-600">This field is required <sup>*</sup></span>}
                </div>
                <button className="flex justify-center mt-4 align-center rounded-full border border-black py-1 mb-3 bg-orange-300 text-sm 
                smallDesktop:text-base xl:text-2xl font-normal cursor-pointer py-[0.5rem]">Send Message</button>
            </form>
        </div>
    )
}
