import { Link } from 'react-router-dom';
import aboutushomepagebgPng from '../../Assets/AboutUsHomePageBg/aboutushomepagebg.png'
import aboutushomepagebgJpg from '../../Assets/AboutUsHomePageBg/aboutushomepagebg.jpg'
import aboutushomepagebgWebp from '../../Assets/AboutUsHomePageBg/aboutushomepagebg.webp'
import aboutushomepagebgAvif from '../../Assets/AboutUsHomePageBg/aboutushomepagebg.avif'
import companylogo from '../../Assets/companylogo.png'
import { IoArrowUp } from "react-icons/io5";

export default function HeroSection() {
    return (
        <div className='h-[57vh] sm:h-[100vh] w-[100vw] relative flex justify-center'>
            <picture>
                <source srcSet={aboutushomepagebgWebp} type="image/webp"/>
                <source srcSet={aboutushomepagebgAvif} type="image/avif"/>
                <source srcSet={aboutushomepagebgJpg} type="image/jpeg"/>
                <img
                    src={aboutushomepagebgPng} // Fallback image if the browser does not support any of the above formats
                    alt="background"
                    loading="lazy"
                    className='absolute h-[100%] w-[100%] filter brightness-[0.7]'
                />
            </picture>
            <div className='w-[100%] p-[1rem] smallPhone:p-[2rem] sm:p-[3rem] sm:pr-[4rem] flex flex-row-reverse'>
                <Link to="/" className='absolute rotate-90 bg-opacity-60 bg-white backdrop-blur-lg filter 
                brightness-150 rounded-[2rem] cursor-pointer p-[0.6rem]'>
                    <IoArrowUp className='text-[0.8rem] smallPhone:text-[1rem] sm:text-4xl smallDesktop:text-5xl' />
                </Link>
            </div>
            <div className='h-[100%] w-[75%] flex flex-col justify-center items-center absolute gap-y-[1rem] sm:gap-y-[2rem] xl:gap-y-[3rem]'>
                <div className='flex flex-col items-center bg-opacity-60 bg-white backdrop-blur-lg rounded-[2rem] p-[2rem] filter brightness-150'>
                    <img src={companylogo} alt='company logo' loading='lazy' className='w-[6rem] h-[5rem] mostPhone:w-[8rem] mostPhone:h-[7rem] 
                    sm:w-[10rem] sm:h-[9rem] smallDesktop:w-[14rem] smallDesktop:h-[12rem] xl:w-[17rem] xl:h-[15rem]' />
                    <h2 className='text-center text-[1rem] mostPhone:text-[1.3rem] sm:text-[2rem]
                     smallDesktop:text-[2.5rem] xl:text-5xl font-black'>Dhananjay Enterprises</h2>
                </div>
                <p className='text-white font-bold text-[0.8rem] mostPhone:text-[1.2rem] sm:text-[1.5rem] smallDesktop:text-[2rem] xl:text-4xl text-center'>
                    Dhananjay Enterprises started in 2005 and has become know for offering excellent services to our customer over the years.
                </p>
            </div>
        </div>
    )
}