import ourmissionimage1 from '../../Assets/ourmissionimage1.png'
import ourmissionimage2 from '../../Assets/ourmissionimage2.png'

export default function OurMission(){
    return(
        <div className="bg-[#F9F9F9] w-[100vw] h-[29rem] flex justify-center py-[1rem]">
            <div className='w-[90%] mostPhone:w-[78%] xl:w-[70%] flex flex-col-reverse  sm:flex-row items-center gap-x-[2rem]'>
                <div className='flex flex-col gap-y-[1rem] xl:gap-y-[2rem] sm:w-[50%]'>
                    <h2 className='text-xl mostPhone:text-3xl smallDesktop:text-4xl xl:text-5xl font-bold text-center sm:text-start'>Our Mission</h2>
                    <p className='text-xs mostPhone:text-sm smallDesktop:text-lg xl:text-xl w-[100%] xl:w-[80%] font-medium'>Our mission 
                    is to provide reliable and high-quality products and services, ensuring our customers get the best value
                        for their investment. Our main focus has always been honesty, integrity, and commitment. We've successfully completed
                        many big projects on time, thanks to the great support from our partners.
                    </p>
                </div>
                <div className='sm:translate-y-[6rem] md:translate-y-[4rem] lg:translate-y-[2rem] xl:translate-y-[0rem] 
                relative w-[81%] mostPhone:w-[64%] sm:w-[50%] h-[100%]'>
                    <img src={ourmissionimage1} alt='mission' loading='lazy' className='absolute w-[67%] mostPhone:w-[66%] xl:h-[25rem]'/>
                    <img src={ourmissionimage2} alt='mission' loading='lazy' className='absolute w-[73%] mostPhone:w-[70%] 
                    xl:h-[20rem] translate-x-[3rem] smallPhone:translate-x-[6rem] translate-y-[6rem] xl:translate-x-[12rem] 
                    2xl:translate-x-[16rem] md:translate-y-[8rem]'/>
                </div>
            </div>
        </div>
    )
}