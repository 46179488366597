import companylogo from '../Assets/companylogo.png'
import FooterLogoData from '../Data/FooterLogoData'
import { PiCopyrightLight } from "react-icons/pi";

export default function Footer(){
    return(
        <div 
            className='flex justify-center py-[1.5rem] border-t-[2px] border-[grey]' 
            style={{background: 'linear-gradient(27deg, rgba(253,195,0,1) 0%, rgba(255,86,48,1) 100%)'}}>
            <div className='sm:w-[50%] flex flex-col items-center gap-y-[1.5rem] xl:gap-y-[3.5rem]'>
                {/* Company Logo  */}
                <div className='flex flex-col items-center'>
                    <img src={companylogo} alt='company logo' loading='lazy' className='w-[3.5rem] h-[3rem] 
                    smallPhone:w-[5rem] smallPhone:h-[4rem] sm:w-[7rem] sm:h-[6rem] xl:w-[9rem] xl:h-[8rem]'/>
                    <p className='font-bold text-xs smallPhone:text-lg sm:text-2xl xl:text-4xl'>Dhananjay Enterprises</p>
                </div>
                {/* Social Media links  */}
                <div className='flex text-sm smallPhone:text-lg sm:text-2xl xl:text-4xl gap-x-[1rem] smallPhone:gap-x-[2rem]'>
                    {
                        FooterLogoData?.map((Data,index) => (
                            <a key={index} target="_blank" href={Data?.href} rel="noopener noreferrer" className='cursor-pointer'>
                                <Data.Icon/>
                            </a>
                        ))
                    }
                </div>
                <div className='flex items-center font-semibold text-[0.5rem] smallPhone:text-[0.7rem] sm:text-[1rem] xl:mt-[1.5rem] xl:mb-[1rem]'>
                    <PiCopyrightLight />
                    <p>2020 Mockup. All Rights Reserved.</p>
                </div>
            </div>
        </div>
    )
}