import NavbarData from "../Data/NavbarData"
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'react-router-dom';

export default function SideNavbar(){
    return(
        <div className="flex flex-col items-center justify-center h-[100%] gap-y-[4rem]">
            <div className="flex flex-col text-white font-semibold gap-[5vw] items-center text-[3vw] 
            mostPhone:text-[1rem]">
                {
                    NavbarData?.map((content) => (
                        content?.navigate === '/AboutUs' || content?.navigate === '/ClientReview' ?
                            <Link to={content?.navigate}  key={content?.index} className='pl-[5px] pr-[7px] cursor-pointer text-center'>{content?.data}</Link>
                            : <AnchorLink href={content?.navigate} key={content?.index}>
                                <div className='pl-[5px] pr-[7px] cursor-pointer text-center'>{content?.data}</div>
                            </AnchorLink>
                    ))
                }
            </div>
            {/* contact button  */}
            <button className='text-[3vw] mostPhone:text-[1rem] text-white bg-orangeTheme font-bold px-[2vw] py-[1vw] mostPhone:px-8 mostPhone:py-2 
            rounded-tl-full rounded-bl-full rounded-tr-full rounded-br-full self-center'>
                    Contact
                </button>
        </div>
    )
}