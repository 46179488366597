import { Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import AboutUsPage from "./Pages/AboutUsPage";
import ClientReviewPage from "./Pages/ClientReviewPage";

function App() {
  return(
    <Routes>
      <Route path="/" element={
        <div className="overflow-x-hidden">
          <HomePage/>
        </div>}>
      </Route>
      <Route path='/AboutUs' element={<AboutUsPage/>}/>
      <Route path='/ClientReview' element={<ClientReviewPage/>}/>
    </Routes>
  )
}

export default App;
