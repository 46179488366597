const CustomerReviewData = [
    {
        name:'Name',
        label:'Name',
        placeholder:'John',
        type:'text',
        id:1,
    },
    {
        name:'Surname',
        label:'Surname',
        placeholder:'Deo',
        type:'text',
        id:2,
    },
    {
        name:'Customer_Email',
        label:'Customer Email',
        placeholder:'abcd@gmail.com',
        type:'email',
        id:3,
    },
    {
        name:'Customer_Mobile_No',
        label:'Customer Mobile No',
        placeholder:'xxxxx xxxxx',
        type:'tel',
        id:4,
    },
]

export default CustomerReviewData;