import Footer from "../Components/Footer";
import FounderSection from "../Sections/AboutUsSection/FounderSection";
import HeroSection from "../Sections/AboutUsSection/HeroSection";
import OurMission from "../Sections/AboutUsSection/OurMission";

export default function AboutUsPage() {

    return (
        <div>
            <HeroSection/>
            <OurMission/>
            <FounderSection/>
            <Footer/>
        </div>
    )
}